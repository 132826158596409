@font-face {
  font-family: "NothingYouCouldDo";
  src: local("NothingYouCouldDo"), url("./fonts/NothingYouCouldDo-Regular.ttf");
  font-weight: bold;
}

.MuiTypography-root,
p.MuiTypography-root {
  color: #262626;
}

.tabPanelPadding {
  padding: 0px;
}

.image {
  max-width: 100%;
  width: inherit;
  border-radius: 16px;
}

.logo {
  font-size: 45px;
  font-family: "NothingYouCouldDo";
  color: #883997;
  text-align: center;
}

.separatorLarge {
  height: 40px;
  width: 100%;
  display: block;
}

.separatorSmall {
  height: 20px;
  width: 100%;
  display: block;
}

.dataEvento {
  margin-top: 35px;
}

p {
  color: rgba(0, 0, 0, 0.6);
}

.galleryOpenedImage {
  max-width: 100%;
  max-height: 100%;
}